import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"

import SommiersSingleProductSection from "../../../components/SommiersSingleProductSection"
import SommierZonesCompositionSection from "../../../components/SommierZonesCompositionSection"
import SommiersTechnologySection from "../../../components/SommiersTechnologySection"
import SommiersPackProductSection from "../../../components/SommiersPackProductSection"
import MatelasTestimonialSection from "../../../components/Products/MatelasTestimonialSection"
import Footer from "../../../components/Layout/Footer"
import SeoComp from "../../../components/SeoComp"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const SommiersZones = ({ intl, data }) => {
  const handleHashScrolling = () => {
    $(document).on("click", 'a[href^="#"]', function(event) {
      if ($($.attr(this, "href")).length) {
        event.preventDefault()
        $("html, body").animate(
          {
            scrollTop: $($.attr(this, "href")).offset().top - 0,
          },
          1500
        )
      }
    })
  }

  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/shop/`)
    isBrowser && handleHashScrolling()
  }, [intl.locale])

  return (
    data &&
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data.allDirectusSommierZonesTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "shop/sommiers-zones",
            en: "",
            de: "",
            es: "",
          }}
          noindex={true}
          nofollow={true}
        />

        {data.allDirectusSommierZonesTranslation.nodes[0]
          .enplus_pack_product_section && (
          <SommiersPackProductSection
            data={
              data.allDirectusSommierZonesTranslation.nodes[0]
                .enplus_pack_product_section
            }
            price={data.allShopifyProduct.edges}
            id="sommier-pack-section"
            page="sommier-enplus"
          />
        )}

        {data.allDirectusSommierZonesTranslation.nodes[0]
          .technology_section && (
          <SommiersTechnologySection
            data={
              data.allDirectusSommierZonesTranslation.nodes[0]
                .technology_section
            }
          />
        )}

        {data.allDirectusSommierZonesTranslation.nodes[0]
          .enplus_complement_section && (
          <SommierZonesCompositionSection
            data={
              data.allDirectusSommierZonesTranslation.nodes[0]
                .enplus_complement_section
            }
          />
        )}

        {data.allDirectusSommierZonesTranslation.nodes[0]
          .enplus_product_section && (
          <SommiersSingleProductSection
            data={
              data.allDirectusSommierZonesTranslation.nodes[0]
                .enplus_product_section
            }
            price={data.allShopifyProduct.edges}
            page="sommier-enplus"
          />
        )}

        {data.allDirectusSommierZonesTranslation.nodes[0]
          .testimonial_section && (
          <MatelasTestimonialSection
            data={
              data.allDirectusSommierZonesTranslation.nodes[0]
                .testimonial_section
            }
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusSommierZonesTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        enplus_product_section {
          section_title
          section_subtitle
          list {
            image
            alt
            name
            title
            text
            top_text
            button_url
          }
        }
        enplus_complement_section {
          section_title
          mobile_section_title
          image
          mobile_image
          alt
          list {
            title
            text
            url
          }
        }
        technology_section {
          section_title
          section_subtitle
          image
          alt
          list {
            image
            alt
            text
          }
        }
        enplus_pack_product_section {
          section_title
          section_subtitle
          list {
            image
            alt
            name
            title
            subtitle
            text
            top_text
            button_text
            button_url
          }
        }
        testimonial_section {
          list {
            image
            alt
            text
            mobile_text
            popup_text
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allShopifyProduct(sort: { fields: handle }) {
      edges {
        node {
          handle
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: {
        language: { eq: $lang }
        seo: { page_name: { eq: "sommiers_zones" } }
      }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(SommiersZones)
